import { Injectable } from '@angular/core';
import { APP_ROUTES } from 'app/constants/app-routes';
import { PageCode, PageId } from 'app/constants/enums';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: 'link' | 'dropDown' | 'icon' | 'separator' | 'extLink';
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  code: PageCode;
  pageId?: string
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
  code: string;
  pageId?: string
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {

  plainMenu: IMenuItem[] = [

    {
      name: 'Home',
      type: 'link',
      icon: 'house',
      state: APP_ROUTES.WELCOME,
      pageId: 'sales_admin_welcome',
      code: PageCode.Welcome
    },
    // {
    //   name: 'Sets',
    //   type: 'link',
    //   icon: 'person',
    //   state: 'sets',
    //   code: PageCode.Profile
    // },
    // {
    //   name: 'Customers',
    //   type: 'link',
    //   icon: 'groups',
    //   state: 'customers-list',
    //   pageId:'customers_list',
    //   code: PageCode.Profile
    // },
    // {
    //   name: 'Orders',
    //   type: 'link',
    //   icon: 'local_mall',
    //   state: 'order-list',
    //   pageId:'order_list',
    //   code: PageCode.Profile
    // },

    // {
    //   name: 'Order Products',
    //   type: 'link',
    //   icon: 'list',
    //   state: 'order-products',
    //   pageId:'order_products_list',
    //   code: PageCode.Profile
    // },

    {
      name: 'Sales Person',
      type: 'dropDown',
      icon: 'person',
      code: PageCode.Profile,
      sub: [
        {
          name: 'Sales Person List',
          state: APP_ROUTES.SALESPERSON_LIST, // Use the constant defined in your routes file
          code: 'sales_person_planning',
          pageId: PageId.crm_sales_person_list,
        },
        {
          name: 'Attendance',
          state: APP_ROUTES.ATTENDANCE, // Use the constant defined in your routes file
          code: 'sales_person_planning',
          pageId: PageId.crm_attendance,
        },
        {
          name: 'Planning',
          state: APP_ROUTES.SALESPERSON_PLANNING, // Use the constant defined in your routes file
          code: 'sales_person_planning',
          pageId: PageId.crm_salesperson_planning,
        },
        {
          name: 'Tasks',
          state: APP_ROUTES.TASKS, // Use the constant defined in your routes file
          code: 'crm_task_report',
          pageId: PageId.crm_task_report,
        },
        {
          name: 'Interactions',
          state: APP_ROUTES.INTERACTION_LIST, // Use the constant defined in your routes file
          code: 'sales_interactions',
          pageId: PageId.crm_interactions,
        }, {
          name: 'Performance Report',
          state: APP_ROUTES.PERFORMANCE_REPORT, // Use the constant defined in your routes file
          code: 'sales_performance_report',
          pageId: PageId.crm_performance_report,
        }
      ]
    },


    // {
    //   name: 'Customer Module',
    //   type: 'dropDown',
    //   icon: 'list',
    //   code: PageCode.Profile,
    //   sub: [
    //     { name: 'Customers', state: 'customers/customer-list', code: 'customer_page_view' },
    //     { name: 'Business', state: 'customers/business-list', code: 'business_page_view' },
    //   ]
    // },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() { }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
    //     this.menuItems.next(this.separatorMenu);
    //     break;
    //   case 'icon-menu':
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.plainMenu);
    // }
    this.menuItems.next(this.plainMenu);
  }
}
