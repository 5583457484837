import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class UserRoleGuard implements CanActivate {
  constructor(private router: Router, private snack: MatSnackBar) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // TODO
    // var user = this.jwtAuth.getUser();
    // if (
    //   user && this.hasPermission(route.data?.code)
    // ) {
      return true;
    // } else {
    //   this.snack.open('You do not have access to this page!', 'OK');
    //   this.router.navigate(['sessions/unauthorized'])
    //   return false;
    // }
  }
  hasPermission(permission: string): boolean {
    // TODO
    const permissions = []//this.jwtAuth?.user?.permissions;
    const found = (permissions && permissions.find(item => item === permission)) !== undefined;
    // return found;

    return true;
  }
}


