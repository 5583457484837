import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { APP_ROUTES } from './constants/app-routes';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: '/sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./pages/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: APP_ROUTES.WELCOME,
        loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule),
        data: { title: APP_ROUTES.WELCOME, breadcrumb: APP_ROUTES.WELCOME }
      },
      {
        path: APP_ROUTES.SEARCH,
        loadChildren: () => import('./pages/search/search-view.module').then(m => m.SearchViewModule)
      },
      {
        path: APP_ROUTES.CUSTOMER_LIST,
        loadChildren: () => import('./pages/customers/customers.component-module').then(m => m.CustomersListModule)
      },
      {
        path: APP_ROUTES.SALESPERSON_PLANNING,
        loadChildren: () => import('./pages/salesperson-planning/salesperson-planning.module').then(m => m.SalesPersonPlanningModule),
        data: { title: APP_ROUTES.SALESPERSON_PLANNING, breadcrumb: APP_ROUTES.SALESPERSON_PLANNING },
      },
      {
        path: APP_ROUTES.TASKS,
        loadChildren: () => import('./pages/tasks/tasks.component-module').then(m => m.TasksListModule),
        data: { title: APP_ROUTES.TASKS, breadcrumb: APP_ROUTES.TASKS },
      },
      {
        path: APP_ROUTES.TASK_DETAILS,
        loadChildren: () => import('./pages/tasks/tasks.component-module').then(m => m.TasksListModule),
      },
      {
        path: APP_ROUTES.INTERACTION_LIST,
        loadChildren: () => import('./pages/interactions/interactions.component-module').then(m => m.InteractionListModule)
      },
      {
        path: APP_ROUTES.SALESPERSON_LIST,
        loadChildren: () => import('./pages/salesperson/salesperson.component-module').then(m => m.SalespersonModule)
      },
      {
        path: APP_ROUTES.ATTENDANCE,
        loadChildren: () => import('./pages/attendance/attendance.component-module').then(m => m.AttendanceModule)
      }, {
        path: APP_ROUTES.PERFORMANCE_REPORT,
        loadChildren: () => import('./pages/performance/performance.component-module').then(m => m.PerformanceModule)
      },

    ]
  },
  // {
  //   path: '**',
  //   redirectTo: 'sessions/404'
  // }
];

