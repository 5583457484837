
export const DB_PATH = {
  CUSTOMERS: "Customers",
  CUSTOMER: "Customer",
  MERCHANT: "Merchant",
  DEALER: "Dealer",
  B2B_AREA_COORDINATES: "b2bAreaCoordinates",
  SERVICE_AREAS: "serviceAreas",
  MARKETINGEXECUTIVE: "MarketingExecutive",
  ME_WALLET: "MEWallet",
  REFERRELS: "Referrel",
  CRM: "Crm",
  FIELDAGENT: "FieldAgent",
  MERCHANTS: "Merchants",
  FIELDAGENTS: "FieldAgents",
  ORDER: "Order",
  ORDERS: "Orders",
  MAINORDERS: "MainOrders",
  REFUNDREQUESTS: "RefundRequests",
  NOTIFICATIONS: "Notifications",
  ORDERS_ARCHEIVED: "OrdersArcheived",
  CURRENT_ORDERS: "CurrentOrders",
  COMPLETED_ORDERS: "CompletedOrders",
  QUOTATIONS: "quotations",
  PAYMENT: "payment",
  PAYMENT_IN: "paymentIn",
  PAYMENT_OUT: "paymentOut",
  COUPONS: "Coupons",
  GLOBAL_COUPONS: "GlobalCoupons",
  DEALER_COUPONS: "DealerCoupons",
  CUSTOMER_SPECIAL_COUPONS: "CustomerCoupons",
  DEALER__STAGING_COUPONS: "DealerStagingCoupons",
  SLOTS: "Slots",
  DELIVERY_SLOTS: "DeliverySlots",
  INVENTORY_SLOTS: "InventorySlots",
  MASTER_SLOTS: "MasterSlots",
  DEALER_SLOTS: "DealerSlots",
  MEDICINE: "Medicine",
  BUTCHERY: "Butchery",
  BUTCHERY_SERVICES: "ButcheryServices",
  MASTER_BUTCHERY_SERVICES: "MasterButcheryServices",
  DEALER_BUTCHERY_SERVICES: "DealerButcheryServices",
  AREA_WISE_CUSTOMER_SETTING: "AreaWiseCustomerSetting",

  QWIPO_SERVICES: "QwipoServices",
  QWIPO_SERVICES_DELIVERY_SLOTS: "deliverySlots",
  QWIPO_SERVICES_INVENTORY_SLOTS: "inventorySlots",

  SERVICES: "Services",
  APP_SETTINGS: "AppSettings",
  APP_MESSAGE_SETTINGS: "common/TextMessges",


  PAYMENT_TRANSACTIONS: "PaymentTransactions",
  ACTIVITIES: "Activities",
  USER_ACTIVITIES: "UserActivities",
  //For enums
  //   PROVIDER_TYPE: 'Provider',
  //   ['Provider' + ProviderType.Dealer]: 'Dealer',
  //   ['Provider' + ProviderType.Master]: 'Master',
  BACKENDJOBS: "BackendJobs",
  //   ORDER_TYPE: 'Order',
  //   ['Order' + OrderType.Butchery]: 'Butchery',
  //   ['Order' + OrderType.Medicine]: 'Medicine',
  //   ['Order' + OrderType.Sweets]: 'Sweets',
  //   ['Order' + OrderType.Food]: 'FoodCurries',
  //   ['Order' + OrderType.Nursery]: 'Nursery',
  //   ['Order' + OrderType.VegetablesFruits]: 'VegetablesFruits',
  //   ['Order' + OrderType.CakesBakery]: 'CakesBakery',
  //   ['Order' + OrderType.PoojaNeeds]: 'PoojaNeeds',
  //   ['Order' + OrderType.Milk]: 'Milk',
  //   ['Order' + OrderType.Groceries]: 'Groceries',
  //   ['Order' + OrderType.OrganicGroceries]: 'OrganicGroceries',
  //   ['Order' + OrderType.HealthDiet]: 'HealthDiet',
  //   ['Order' + OrderType.CarServices]: 'CarServices',
  //   ['Order' + OrderType.Laundry]: 'Laundry',
  //   ['Order' + OrderType.MilkSubscription]: 'MilkSubscription',

  MESSAGE_HANDLER_FOR_CLOUD_FUNCTIONS: "MessageHandlerForCloudFunctions",
  COMPANIES: "Companies",
  INCENTIVES_SETTING: "IncentivesSetting",
  SETS: "Sets",
  SET_AREA_COORDINATES: "AreaCoordinates",
  SET_REPORT: "Report",
  SET_CUSTOMERS: "Customers",
  SETS_GEO_COORDINATES: "GeoCoordinates",
  SALES_PERSON_CLUSTERS: "SalesPersonClusters",
  SALES_PERSON: "SalesPerson",
  INTERACTIONS: "Interactions",
  TASKS: "Tasks",
  SALES_PERSON_CLUSTERS_SUB_COLLECTION: "Customers",
  TASKS_REPORT: "TasksReport",
  MESSAGE_QUEUE_STATUS: "MessageQueueStatus",
  USERS: "Users",
  ATTENDANCE_REPORT: "AttendanceReport",
  CALENDAR: "Calendar",
  LEAVE_REPORT: "LeaveReport",


};
