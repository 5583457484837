import { Injectable } from "@angular/core";
import { Database, equalTo, get, objectVal, orderByChild, query, ref, set, update } from '@angular/fire/database';
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FirebaseDbService {

    constructor(private database: Database) {
    }

    fetchDataFromNode(nodePath: string): Observable<any> {
        const doc = ref(this.database, nodePath);

        return objectVal(doc);
    }

    queryDataByValue(nodePath: string, field: string, value: any): Observable<any[]> {
        const dataRef = ref(this.database, nodePath);
        const queryRef = query(dataRef, orderByChild(field), equalTo(value));

        return objectVal(queryRef);
    }


    getDataFromNode(nodePath: string): Promise<any> {
        const doc = ref(this.database, nodePath);

        return get(doc);
    }

    getDataByValue(nodePath: string, field: string, value: any): Promise<any> {
        const dataRef = ref(this.database, nodePath);
        const queryRef = query(dataRef, orderByChild(field), equalTo(value));
        return get(queryRef);
    }

    async updateData(nodePath: string, data: any): Promise<void> {
        const doc = ref(this.database, nodePath);

        return update(doc, data);
    }

    setData(nodePath: string, data: any): Promise<void> {
        const doc = ref(this.database, nodePath);
        return set(doc, data);
    }
}
