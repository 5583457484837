import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// SERVICES
import { ThemeService } from "./services/theme.service";
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./guards/auth.guard";
import { UserRoleGuard } from "./guards/user-role.guard";
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { AppLoaderService } from "./services/app-loader/app-loader.service";

import { SharedComponentsModule } from "./components/shared-components.module";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { SharedDirectivesModule } from "./directives/shared-directives.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedMaterialModule } from "./shared-material.module";

import {MatSelectModule} from '@angular/material/select';
@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    SharedMaterialModule,
    MatSelectModule
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    UserRoleGuard,
    AppConfirmService,
    AppLoaderService,
  ],
  exports: [
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    SharedMaterialModule,
    MatSelectModule
  ],
})
export class SharedModule {}
