import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable, from } from "rxjs";
import { AuthService, LocalStoreService } from "app/services";
import { mergeMap } from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
    private ls: LocalStoreService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.getToken()).pipe(
      mergeMap(token => {
        const changedReq = token
          ? this.addAuthorizationHeader(req, token)
          : req;

        return next.handle(changedReq);
      })
    );
  }

  private async getToken(): Promise<string | null> {
    return this.ls.getItem('idToken') || await this.authService.getUserIDTokenAndCache();
  }

  private addAuthorizationHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const res = req
    console.log('api res', res)
    if (res.url == "https://fcm.googleapis.com/fcm/send" || req.url.includes('revoke_user_token_uid') || req.url.includes('getSalesPersonReport')) {
      return req
    } else if (res.headers.get('isPaysharpPayments') == "true") {
      res.headers.delete('isPaysharpPayments')
      return req
    }
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'http://localhost:4200',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
      },
    });
  }
}
