export const APP_ROUTES = {
    WELCOME: 'home',
    SIGNIN: "signin",
    PROFILE: "profile",
    SEARCH: "search",
    COUPON: "coupon",
    SETS: "sets",
    ORDER: "order",
    CUSTOMER: "customer",
    CUSTOMER_DETAILS: "customer-details",
    CUSTOMER_SEARCH: "customer-search",
    ORDER_DETAILS: "order-details",
    CRM_HOME: "",
    CUSTOMER_LIST: "customers-list",
    INTERACTION: "interaction",
    INTERACTION_LIST: "interaction-list",
    ORDER_LIST: "order-list",
    ORDER_PRODUCTS: "order-products",
    TASKS: "task-report",
    TASK_DETAILS: "task-details",
    SALESPERSON_PLANNING: "salesperson-planning",
    SALESPERSON_LIST: "salesperson-list",
    ATTENDANCE: "attendance-report",
    PERFORMANCE_REPORT: "performance",
    SALESPERSON_CREATE: "create-salesperson",
    EDIT_SALESPERSON_PROFILE: "edit-salesperson-profile",


}