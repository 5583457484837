import { config } from "config";

export const environment = {
  production: false,
  apiURL: config.apiUrl,
  coreApiUrl: config.coreApiUrl,
  firebaseConfig: config.firebaseConfig,
  ssoUrl: config.ssoUrl,
  cloudFunctionsUrl: config.cloudFunctionsUrl,
  coreUrl: config.coreUrl,
  messagingServiceUrl: config.messagingServiceUrl
};
