import { AbstractControl, FormGroup } from "@angular/forms";
import { BusinessType, ClusterType } from "app/enums/enums";
import { getRandomValues } from "crypto";
import Swal from "sweetalert2";

export function generatePassword() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function generateClusterId() {
  var text = "AREA";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function generateMessageQueueId() {
  var text = "MESQU";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

export function areEqual(obj1: {}, obj2: {}) {
  return Object.keys(obj1).every((key) => {
    return obj2.hasOwnProperty(key) && (obj1[key] == obj2[key]);
  });
};

export function isValidEmail(email: string) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function isValidBusinessName(name: string) {
  var reg = /^[a-zA-Z0-9& ]{1,100}$/;
  return reg.test(name);
}
export function isValidName(name: string) {
  var reg = /^[a-zA-Z& ]{2,100}$/;
  return reg.test(name);
}
export function isValidMobileNumber(name: string) {
  var reg = /^\d{10}$/;
  return reg.test(name) && name.toString().length == 10;
}
export function isValidPincode(pin: string) {
  var reg = /^\d{6}$/;
  return reg.test(pin) && pin.toString().length == 6;
}
export function isEmptyValue(data: string) {
  if (data === " " || data === "" || data == "0" || data === undefined || data === null) {
    return true;
  }
  return false;
}
export function isEmptyNumber(data: number) {
  if (data === undefined || data === null || data === 0) {
    return true;
  }
  return false;
}

export function validateDecimal(decimalNumber: any) {
  var reg = /^[0-9]{0,6}\d*(\.\d{0,2})?$/
  return reg.test(decimalNumber);
}
export function deepEqual(a, b) {
  if (a === b) {
    return true;
  }
  if (a === null ||
    typeof a !== "object" ||
    b === null ||
    typeof b !== "object") {
    return false;
  }
  var propsInA = 0;
  var propsInB = 0;
  for (var prop in a) {
    propsInA += 1;
  }
  for (var prop in b) {
    propsInB += 1;

    if (!(prop in a) || !deepEqual(a[prop], b[prop]))
      return false;
  }
  return propsInA === propsInB;
}





export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}
export function unique(values) {
  let n: any = {}, r: any = [];
  for (let i = 0; i < values.length; i++) {
    if (!n[values[i]]) {
      n[values[i]] = true;
      r.push(values[i]);
    }
  }
  return r;
}

export function toCustomArray(object: Object = {}) {
  object = object || {};

  return Object.keys(object)
    .map(key => {
      let actualObject = object[key];
      let idExists = Object.keys(actualObject).some(property => property === "id");
      actualObject["id"] = key;
      return object[key];
    });
}
export function toCustomObject(array, key = 'id') {
  return array.reduce((result, item) => {
    result[item[key]] = item;
    return result;
  }, {});
}

export function getStartDayOfMonthTimeStamp(selectedDate: Date) {
  // let date = new Date();
  let firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
  let formattedFirstDay = firstDay.getDate() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getFullYear();
  return getTimeStamp(formattedFirstDay);
}
function getTimeStamp(myDate) {
  myDate = myDate.split("-");
  let newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
  return new Date(newDate).getTime();
}
export function getEndDayOfMonthTimeStamp(selectedDate: Date) {
  // let date = new Date();
  let lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
  let formattedLastDay = lastDay.getDate() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getFullYear();
  return getTimeStamp(formattedLastDay);
}




// export function roundAmount(amount: number, toDecimalValue: number) {
//   return Math.round(amount * toDecimalValue) / toDecimalValue;
// }
/**
 *
 * @param amount
 * @param toDecimalValue -- by default will round to 2 digits.
 * @returns
 */
export function roundAmount(amount: number, toDecimalValue: number = 2) {
  // return Math.round(amount * toDecimalValue) / toDecimalValue;
  if (typeof amount == "string") {
    amount = +amount;
  }
  return +(amount.toFixed(toDecimalValue));
}



export function getYears() {
  var currentYear = new Date().getFullYear(), years: Array<number> = [],
    startYear: number = 2017;

  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years.sort(function (a, b) { return b - a });
}
export function getCurrentFormattedDate() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


export function getAutoNumber() {
  var text = "";
  var possible = "0123456789";
  for (var i = 0; i < 8; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}


export function getCustomerStatus() {
  const customerStatus = [
    { name: "Active", status: true, isSelected: true, value: 1 },
    { name: "InActive", status: false, isSelected: false, value: 0 },
  ];
  return customerStatus;
}
export function getCustomerOrderStatus() {
  const customerStatus = [
    { name: "0-7 Days", isSelected: true, value: "0-7" },
    { name: "8-30 Days", isSelected: false, value: "8-30" },
    { name: "31-60 Days", isSelected: false, value: "31-60" },
    { name: "61-90 Days", isSelected: false, value: "61-90" },
    { name: "91-365 Days", isSelected: false, value: "91-365" },
    { name: "365+ Days", isSelected: false, value: "365+" }
  ];
  return customerStatus;
}
export function getCustomerTags() {
  const businessStatus = [
    { name: "Lost", isSelected: false, value: 1 },
    { name: "Lost-Not-Interested", isSelected: false, value: 2 },
    { name: "Business-Shutdown", isSelected: false, value: 3 },
    { name: "Internal-User", isSelected: false, value: 4 },
    { name: "Active-User", isSelected: true, value: 5 },
    { name: "Duplicate", isSelected: false, value: 6 },
    { name: "Temporary-Shutdown", isSelected: false, value: 7 },
    { name: "None", isSelected: false, value: 99 },
  ];
  return businessStatus;
}

export function getBusinessTypes() {
  return [
    { name: "Kirana", isSelected: false, value: "Kirana" },
    { name: "Restaurant", isSelected: false, value: "Restaurant" },
    { name: "Caterers", isSelected: false, value: "Caterers" },
    { name: "TiffinCenters", isSelected: false, value: "TiffinCenters" },
    { name: "PG/Hostel", isSelected: false, value: "PgHostel" },
    { name: "Institutional", isSelected: false, value: "Institutional" },
    { name: "Bakery", isSelected: false, value: "Bakery" },
    { name: "Sweet House", isSelected: false, value: "SweetHouse" },
    { name: "Vegetable shops", isSelected: false, value: "VegetableShops" },
    { name: "Tier2Customer", isSelected: false, value: "Tier2Customer" },
    { name: "Medicals", isSelected: false, value: "Medicals" },
    { name: "bulkcategory", isSelected: false, value: "bulkcategory" },
    { name: "chemist", isSelected: false, value: "Chemist" },
    { name: "cosmetic", isSelected: false, value: "Cosmetic" },
    { name: "supermarket", isSelected: false, value: "Supermarket" },
    { name: "Wholesale", isSelected: false, value: "Wholesale" },
    { name: "Rice Traders", isSelected: false, value: "RiceTraders" },
    { name: "Oil Traders", isSelected: false, value: "OilTraders" },
    { name: "Others", isSelected: false, value: "Others" },
    { name: "None", isSelected: false, value: "None" },
  ]
}

export function getMonthNames() {
  return [
    { name: "January", isSelected: false, value: "january" },
    { name: "February", isSelected: false, value: "february" },
    { name: "March", isSelected: false, value: "march" },
    { name: "April", isSelected: false, value: "april" },
    { name: "May", isSelected: false, value: "may" },
    { name: "June", isSelected: false, value: "june" },
    { name: "July", isSelected: false, value: "july" },
    { name: "August", isSelected: false, value: "august" },
    { name: "September", isSelected: false, value: "september" },
    { name: "October", isSelected: false, value: "october" },
    { name: "November", isSelected: false, value: "november" },
    { name: "December", isSelected: false, value: "december" },

  ]
}

export function orderStatus() {
  return [
    { name: "OrderPlaced", id: 1 },
    // { name: "OrderConfirmed", id: 3 },
    // { name: "ReadyForPickup", id: 4 },
    // { name: "PickedUpOrder", id: 5 },
    { name: "ReadyForShipment", id: 6 },
    { name: "OutforDelivery", id: 7 },
    { name: "Delivered", id: 8 },
    // { name: "NotDelivered", id: 9 },
    { name: "Cancelled", id: 10 },
    // { name: "CustomerCancellationRequest", id: 11 },
    // { name: "None", id: 99 }
  ]
}

export function getClusterTypeValueUI(type: ClusterType) {
 
  return {
    1: "general",
    2: "wholesale",
  }[type] || 'general'
}

export function getClusterTypeValue(type: any) {

  return {
    1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 18, 19, 99],
    2: [17],
  }[type] || 'general'
}

export const getDateFormat = (d = new Date()): string =>
  `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;


export function groupBy(arr: any[], prop: string | number) {
  var map: any = new Map();
  map = new Map(Array.from<[any, any], [any, any]>(arr, (obj: Array<any>) => [obj[prop], []]));
  arr.forEach((obj: Array<any>) => map.get(obj[prop]).push(obj));
  return Array.from(map.values());
}

export function getDistinctValues(arr, property) {
  const valuesSet = new Set();
  arr.forEach(obj => {
    valuesSet.add(obj[property]);
  });
  return Array.from(valuesSet);
}

export function getUUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}


export function uuidv4() {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


export function removeEndSpaces(inputString: string) {

  return inputString.toLowerCase().replace(/[^a-z0-9]/g, '');
}

export function removingSpace(inputString: string): string {
  const trimmedAndLowercase = inputString.trim().toLowerCase();
  const cleanedString = trimmedAndLowercase.replace(/ +/g, ' ');

  return cleanedString;
}
export function isAreaNameValid(areaName: string): boolean {
  const areaNameRegex = /^(?!.* {2})[A-Za-z0-9-]+(?:[ \t]+[A-Za-z0-9-]+)*$/;
  const trimmedAreaName = areaName.trim();

  return areaNameRegex.test(trimmedAreaName);
}


export function formatDates(dateStrings: string[]): string[] {
  return dateStrings.map(dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  });
}

export function timeStamp(isoTimestamp) {
  return new Date(isoTimestamp).getTime()
}

export function capitalizeFirstLetter(inputString: string): string {
  if (!inputString) {
    return inputString;
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export function isValidGeoJsonFile(filename: string) {
  const ext = getExtension(filename);
  return ext.toLowerCase() === 'geojson' || ext.toLowerCase() === 'json';
}


function getExtension(filename: string) {
  const parts = filename.split('.');
  return parts[parts.length - 1];
}

export function convertKeysToLowercase(obj: any = {}) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return as is if it's not an object
  }

  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key.toLowerCase()] = obj[key];
    }
  }

  return result;
}


export function chunkArray(array: any[], chunkSize: number) {
  const result: any[] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}


export function extractCoordinates(data: [number, number][]): { latitude: number; longitude: number }[] {
  return data.map(([longitude, latitude]) => ({ latitude, longitude }));
}

export function showWarningMessage(message: string) {
  Swal.fire("warning", message, "warning");
  return new Error(message);
}
export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}


export function convertTimestampToMinutes(timestamp) {
  const minutes = Math.floor(timestamp / 60000); // 1 minute = 60,000 milliseconds
  return minutes;
}

export function generateYears(startYear: number, endYear: number): number[] {
  const yearsArray: number[] = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }
  return yearsArray;
}

export function salespersonAttendanceGuid() {

  return `ATD${getRandomNumberString()}`;
}

function getRandomNumberString() {
  let str = "";
  for (let i = 0; i < 14; i++) {
    str += "0123456789".charAt(Math.floor(Math.random() * 10));
  }

  return str;
}
export function validatePinCode(pinCode: number): boolean {

  if (pinCode.toString().length !== 6) {
    return false; // GST number should be exactly 15 characters long
  }

  const reg = /^\d{6}$/;
  return reg.test(pinCode.toString());
}

export function applyMaxLengthValidation(form: FormGroup, maxLengthConfig: { property: string; maxLength: number }[]) {
  for (const validation of maxLengthConfig) {
    const control: AbstractControl | null = form.get(validation.property);
    if (control) {
      control.valueChanges.subscribe((value: any) => {
        const maxLength = validation.maxLength;
        if (value && value.toString().length > maxLength) {
          control.setValue(value.toString().slice(0, maxLength), { emitEvent: false });
        }
      });
    }
  }
}