import { Injectable } from '@angular/core';
import { FirebaseDbService } from './firebase-db.service';
import { DB_PATH } from 'app/constants/db-path';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { QueryFilter } from 'app/models/query-filters';
import { WhereFilterOp } from 'app/enums/enums';
import { FirestoreService } from './firestore.service';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private firebaseDbService: FirebaseDbService,
        private http: HttpClient,
        public firestoreService: FirestoreService,
    ) {
    }

    fetchUserById(userId: string): Observable<any> {
        return this.firebaseDbService.fetchDataFromNode(`${DB_PATH.CRM}/${userId}`);
    }
    async fetchSalesPersons(value: number): Promise<any> {

        const filters: Array<QueryFilter> = [{
            fieldName: "user_type", fieldValue: value, operator: WhereFilterOp.EqualTo
        }]
        const response = await this.firestoreService.queryCollectionByFilters(DB_PATH.USERS, filters);
        console.log("response ::::", response)
        return response;
    }



    // async fetchUserById(userId: string): Promise<any> {
    //     const userObservable$ = this.firebaseDbService.fetchDataFromNode(`${DB_PATH.CRM}/${userId}`);

    //     try {
    //         const response = await lastValueFrom(userObservable$);
    //         console.log(" response ::: ", response);
    //         return response;
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    // async fetchUserById(userId: string): Promise<any> {
    //     const userObservable$ = this.firebaseDbService.fetchDataFromNode(`${DB_PATH.CRM}/${userId}`);

    //     try {
    //         const response = await userObservable$.pipe(first()).toPromise();
    //         return response;
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    // async fetchUserById(userId: string): Promise<any> {
    //     try {
    //         console.log('fetchUserById userId ::: ', userId);

    //         const userObservable = this.firebaseDbService.fetchDataFromNode(`${DB_PATH.CRM}/${userId}`);
    //         const response = await lastValueFrom(userObservable);
    //         console.log('response ::: ', response);
    //         return response; 
    //     } catch (error) {
    //         console.log('fetchUserById Error  ::: ', error);

    //         throw error;
    //     }

    // }

}
