export enum PageCode {
    Welcome = 0,
    Profile = 1,
    NotFound = 99
}

export const PageId = {
    crm_sales_person_list: "crm_sales_person_list",
    crm_attendance: "crm_attendance",
    crm_salesperson_planning: "crm_salesperson_planning",
    crm_task_report: "crm_task_report",
    crm_interactions: "crm_interactions",
    crm_performance_report:"crm_performance_report"
}