import { Firestore, collection, doc, getDocs, query, where, setDoc, updateDoc, writeBatch, WhereFilterOp, orderBy, startAt, limit, getDoc, addDoc, deleteDoc, deleteField, onSnapshot, startAfter, FieldPath, DocumentData } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FirestoreService {

    constructor(private db: Firestore) { }

    async fetchCollection(collectionName: string) {
        const querySnapshot = await getDocs(collection(this.db, collectionName));
        return querySnapshot.docs.map((doc) => doc.data());
    }

    async queryCollectionByFilter(
        collectionName: string,
        fieldName: string, fieldValue: string | Array<any> | number | boolean, operator: WhereFilterOp
    ): Promise<any[]> {

        const q = query(collection(this.db, collectionName), where(fieldName, operator, fieldValue));

        const querySnapshot = await getDocs(q);
        const collectionDocs = [];
        querySnapshot.forEach((doc) => {
            collectionDocs.push(Object.assign(doc.data(), { id: doc.id }));
        });

        return collectionDocs;
    }

    async queryCollectionByFilters(
        collectionName: string,
        filters: Array<{ fieldName: string; fieldValue: string | Array<any> | number | boolean; operator: WhereFilterOp }>
    ): Promise<any[]> {
        const q = query(collection(this.db, collectionName),
            ...filters.map(filter => where(filter.fieldName, filter.operator, filter.fieldValue))
        );

        const querySnapshot = await getDocs(q);

        const collectionDocs = [];
        querySnapshot.forEach((doc) => {
            collectionDocs.push(Object.assign(doc.data(), { id: doc.id }));
        });

        return collectionDocs
    }


    async queryNextPage(collectionName: string,
        filters: Array<{ fieldName: string; fieldValue: string | Array<any> | number | boolean; operator: WhereFilterOp }>,
        orderByField: string | FieldPath,
        pageSize: number, prevPage: boolean) {
        let next: any;




        if (prevPage) {
            next = query(
                collection(this.db, collectionName),
                orderBy(orderByField, 'asc'),
                limit(pageSize),

                ...filters.map(filter => where(filter.fieldName, filter.operator, filter.fieldValue)),
            );
        } else {
            next = query(
                collection(this.db, collectionName),
                orderBy(orderByField, 'desc'),
                limit(pageSize),

                ...filters.map(filter => where(filter.fieldName, filter.operator, filter.fieldValue)),
            );
        }


        const querySnapshot = await getDocs(next);

        const collectionDocs = [];
        querySnapshot.forEach((doc) => {
            collectionDocs.push(Object.assign(doc.data(), { id: doc.id }));
        });

        return collectionDocs
    }







    async fetchDocument(collection: string, docId: string) {
        const docRef = doc(this.db, collection, docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return null;
        }
    }

    // Will Insert with Auto generated Id
    async insertDoc(collectionName: string, insertEntity: any = {}): Promise<string> {

        const docRef = await addDoc(collection(this.db, collectionName), insertEntity);

        return docRef.id
    }

    // Will Insert with Auto generated Id
    async insertDocWithId(collectionName: string, docId: string, insertEntity: any = {}): Promise<void> {
        try {

            return await setDoc(doc(this.db, collectionName, docId), insertEntity);
        } catch (error) {
            console.log('Firestore Service insertDocWithId error ::: ', error);
        }
    }

    async deleteDoc(collectionName: string, docId: string) {

        return await deleteDoc(doc(this.db, collectionName, docId));
    }

    async deleteFieldDoc(collectionName: string, docId: string, fieldToDelete: string) {
        const documentRef = doc(this.db, collectionName, docId);

        // Create an object with the field to delete
        const fieldsToDelete = {
            [fieldToDelete]: deleteField()
        };

        // Update the document to delete the specified field
        await updateDoc(documentRef, fieldsToDelete);
    }

    async deleteSubCollectionDoc(collectionName: string, docId: string, subCollection: string, subCollectionDocId: string) {
        const mainDocRef = doc(this.db, collectionName, docId);

        return await deleteDoc(doc(mainDocRef, subCollection, subCollectionDocId));
    }

    async updateDocument(collectionName: string, docId: string, updateData: any = {}): Promise<void> {
        try {
            const docRef = doc(this.db, collectionName, docId);

            await updateDoc(docRef, updateData);

            onSnapshot(docRef, (snapshot) => {
                const updatedDoc = snapshot.data();
            });
        } catch (error) {

            throw error;

        }
    }

    async fetchSubCollection(collectionName: string, docId: string, subCollectionName: string) {

        const mainDocRef = doc(this.db, collectionName, docId);
        const querySnapshot = await getDocs(collection(mainDocRef, subCollectionName));

        return querySnapshot.docs.map((doc) => doc.data());
    }

    async fetchSubCollectionDocIds(collectionName: string, docId: string, subCollectionName: string) {

        const mainDocRef = doc(this.db, collectionName, docId);
        const querySnapshot = await getDocs(collection(mainDocRef, subCollectionName));

        const docIds = [];
        querySnapshot.forEach((doc) => {
            docIds.push(doc.id);
        });
        return docIds;
    }

    async querySubCollectionByFilters(
        collectionName: string, docId: string, subCollectionName: string,
        filters: Array<{ fieldName: string; fieldValue: string | Array<any> | number | boolean; operator: WhereFilterOp }>
    ): Promise<any[]> {
        const mainDocRef = doc(this.db, collectionName, docId);
        const q = query(collection(mainDocRef, subCollectionName), ...filters.map(filter => where(filter.fieldName, filter.operator, filter.fieldValue)));

        const querySnapshot = await getDocs(q);

        const collectionDocs = [];
        querySnapshot.forEach((doc) => {
            collectionDocs.push(Object.assign(doc.data(), { id: doc.id }));
        });

        return collectionDocs;
    }

    // Will Insert with Auto generated Id
    async insertDocSubCollection(collectionName: string, docId: string, subCollection: string, insertEntity: any = {}): Promise<string> {
        const mainDocRef = doc(this.db, collectionName, docId);

        const docRef = await addDoc(collection(mainDocRef, subCollection), insertEntity);

        return docRef.id
    }

    // Will Insert with Auto generated Id
    async insertDocWithIdSubCollection(collectionName: string, docId: string,
        subCollection: string, subCollectionDocId: string,
        insertEntity: any = {}): Promise<void> {
        const mainDocRef = doc(this.db, collectionName, docId);

        return setDoc(doc(mainDocRef, subCollection, subCollectionDocId), insertEntity);
    }

    async updateSubCollectionDocument(collectionName: string, docId: string,
        subCollectionName: string, subCollectionDocId: string, updateData: any): Promise<void> {
        const mainDocRef = doc(this.db, collectionName, docId);
        const docRef = doc(mainDocRef, subCollectionName, subCollectionDocId);

        return updateDoc(docRef, updateData);
    }

    async writeDataToSubCollectionInBatches(
        collectionName: string,
        docId: string,
        subCollectionName: string,
        docs: Array<any>
    ) {
        try {
            const mainDocRef = doc(this.db, collectionName, docId);
            const batch = writeBatch(this.db);

            for (const docData of docs) {
                const subCollectionRef = collection(mainDocRef, subCollectionName);
                const subCollectionDocRef = doc(subCollectionRef, docData.id);

                // Use `set` to add the document to the subCollection within the batch
                batch.set(subCollectionDocRef, docData);
            }

            // Commit the batch to add all documents to the subCollection
            await batch.commit();
            console.log(`Added ${docs.length} documents to subCollection ${subCollectionName}`);

            return null;
        } catch (error) {
            console.error('Error writing data to subCollection:', error);
            throw error;
        }
    }

}
