import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "app/services";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.authService.isLoggedIn().pipe(
      map((isLoggedIn: any) => {
        if (isLoggedIn) {
          return true;
        } else {
          return true;
        }
      }),
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          this.router.navigate(["/sessions/signin"], {
            queryParams: {
              return: state.url
            }
          });
        }
      })
    );
  }
}
