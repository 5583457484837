import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'app/services';
import { Permissions } from 'app/shared/services/permissions';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {
  permissionObj = {}
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @ViewChild('sidenav') sidenav: ElementRef;
  private permissions = Permissions;

  constructor(public authService: AuthService
  ) { }
  ngOnInit() {
    this.authService.permission$.subscribe(permissionObj => {
      this.permissionObj = permissionObj
    })
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const links = this.sidenav.nativeElement.querySelectorAll('li[appdropdownlink]');
      [...links].forEach(link => {
        if (link.querySelector('a.open')) {
          link.classList.add('open');
        }
      })
    })
  }

  hasPermission(permission: number): boolean {
    const permissions = []// this.jwtAuth?.user?.permissions;
    const found = (permissions && permissions.find(item => item === permission)) !== undefined;
    // return found;
    return true;

  }

  canAccess(item: any): boolean {
    if (!this.permissionObj) {
      return false;
    }

    switch (item?.type) {
      case "dropDown": {
        if (item?.sub) {
          const isView = item.sub.some((page) => this.permissionObj[page.pageId]?.view === true);
          return isView;
        } else {
          return false;
        }
      }
      case "link":
      default: {
        return this.permissionObj[item?.pageId]?.view || false;
      }
    }
  }

  showDropDown(subPages: any[]) {
    let isView = subPages.filter((page) => { return (this.permissionObj[page.pageId]?.view == true) });
    return isView.length > 0;
  }
}