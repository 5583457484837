export enum OrderStatus {
  OrderPlaced = 1,
  QuotationRequested = 2,
  OrderConfirmed = 3,
  ReadyForPickup = 4,
  PickedUpOrder = 5,
  ReadyForShipment = 6,
  OutforDelivery = 7,
  Delivered = 8,
  NotDelivered = 9,
  Cancelled = 10,
  CustomerCancellationRequest = 11,
  None = 99
}

export enum CustomerOrderStatus {
  Ordered = 1,
  Verified = 2,
  Dispatched = 3,
  Cancelled = 4,
  Delivered = 5,
}
export enum RoleType {
  SupportMember = 1,
  TeleCaller = 2,
  internalDeparment = 3
}
export enum UserType {
  Customer = 1,
  Dealer = 2,
  Merchant = 3,
  FieldAgent = 4,
  SupportMember = 5,
  MarketingAgent = 6,
  SalesPerson = 7,
  SalesLead = 8,
  SalesManager = 9,
  Guest = 99,
}

export enum CreateUserType {
  SuperAdmin = 1,
  Admin = 2,
  DataAdmin = 3,
  OperationsExecutive = 4,
  ProductManager = 5,
  ProcurementManager = 6,
  ProcurementExecutive = 7,
  AccountingManager = 8,
  AccountingExecutive = 9,
  MarketingManager = 10,
  MarketingExecutive = 11,
  VendorManager = 12,
  VendorExecutive = 13,
  SalesManager = 14,
  SalesPerson = 15,
  SalesLead = 16,
  CustomerServiceManager = 17,
  CustomerServiceExecutive = 99,

}

export enum PaymentStatus {
  Paid = 1,
  UnPaid = 2,
  InProgress = 3,
  None = 99
}
export enum OrderPaymentStatus {
  Initiated = 1,
  Success = 2,
  Failure = 3,
  None = 99
}
export enum DealerPaymentStatus {
  Due = 1,
  InProgress = 2,
  Paid = 3,
  None = 99
}
export enum PrescriptionType {
  Image = 1,
  Text = 2,
  None = 99
}
export enum ThirdPartyRequestedBillStatus {
  BillRequested = 1,
  BillProcessed = 2,
  None = 99
}
export enum DeliveryStatus {
  Delivered = 1,
  NotDelivered = 2,
  Cancelled = 3,
  None = 99
}
export enum PaymentType {
  COD = 1,
  Cashless = 2,
  None = 99
}
export enum StakeModel {
  QwipoStake = 1,
  DealerStake = 2,
  None = 99
}
export enum GateWayType {
  Paytm = 1,
  Instamojo = 2,
  None = 3,
  Paysharp = 4
}
export enum RefundReasonType {
  OrderModified = 1,
  CustomerCancelled = 2,
  InvalidOrder = 3,
  None = 99
}
export enum OrderType {
  Medicine = 1,
  Butchery = 2,
  Sweets = 3,
  Food = 4,
  Nursery = 5,
  VegetablesFruits = 6,
  CakesBakery = 7,
  Milk = 8,
  Fuel = 9,
  Groceries = 10,
  PoojaNeeds = 11,
  OrganicGroceries = 12,
  HealthDiet = 13,
  CarServices = 14,
  Laundry = 15,
  MilkSubscription = 16,
  None = 99
}
export enum CouponDiscountType {
  Price = 1,
  Percentage = 2,
  None = 99
}
export enum SwalMessageTypes {
  Error = "error",
  Warning = "warning",
  Success = "success"
}

export enum BusinessType {
  Restaurant = 1,
  Kirana = 2,
  Caterers = 3,
  TiffinCenters = 4,
  PgHostel = 5,
  Bakery = 6,
  SweetHouse = 7,
  VegetableShops = 8,
  Others = 10,
  Tier2Customer = 11,
  Medicals = 12,
  Chemist = 14,
  Cosmetic = 15,
  Supermarket = 16,
  Wholesale = 17,
  RiceTraders = 18,
  OilTraders = 19,
  Institutional = 20,
  bulkcategory = 21,
  None = 99
}

export enum CouponCharacterType {
  Usual = 1,        // Usual Coupon which we are using from the past days
  EarlyBirdSale = 2, // Applicable on Pre_Orders and Coupon discount is only for EarlyBirdSale Products
  QwipoCashBack = 3, // Defines a limit cash back value of qwipo cash back coupon cashbacks you for a succesful delivered order.
  FreeDelivery = 4,  // a coupon type which exumpts the delivery charges if any
  CategoryBased = 5, // a coupon gives a discount on selected categories only
  ProductBased = 6,  // a coupon gives a discount on selected Products only1
  GatewayBased = 7,  // a coupon gives a cash back or discount value for selected Payement gateways
  CustomerSpecialCoupon = 8, // a coupon for customer special add
  None = 99,
}
export enum RefundStatus {
  Initiated = 1,
  Succeeded = 2,
  Requested = 3,
  None = 99
}
export enum CouponStatus {
  Applied = 1,
  Failed = 2,
  Expired = 3,
  None = 99
}
export enum CouponStage {
  Staging = 1,
  Final = 2,
  None = 99
}
export enum ApprovalStatus {
  Success = 1,
  Pending = 2,
  Rejected = 3,
  Deactivated = 4,
  Expired = 5,
  None = 99
}
export enum CouponProvider {
  Qwipo = 1,
  Dealer = 2,
  None = 3,
}
export enum ProviderType {
  Master = 1,
  Dealer = 2,
  Other = 3,
}
export enum PriceSelection {
  ProductPrice = 1,
  LookUpPrice = 2,
  None = 99
}
export enum MerchantType {
  Medicine = 1,
  Butchery = 2,
  None = 99
}
export enum MerchantBusinessType {
  Wholesaler = 1,
  Distributor = 2
}
export enum PrescriptionType {
  prescriptionImageUrl = 1,
  text = 2
}
export enum OrderSource {
  Customer = 1,
  B2B = 2,
  None = 99
}
export interface ValuePair {
  id: number,
  name: string,

}
export enum SalesPersonStatus {
  InActive = 0,
  Active = 1
}
export enum PriceUnit {
  KG = 1,
  Litre = 2,
  Grams = 3,
  MilliLitres = 4,
  SingleItem = 5,
  Pack = 6,
  Piece = 7,
  None = 99
}
export var states = [
  {
    "id": 1,
    "name": "Andhra Pradesh",
    "stateCode": 37
  },
  {
    "id": 2,
    "name": "Arunachal Pradesh",
    "stateCode": 12
  },
  {
    "id": 3,
    "name": "Assam",
    "stateCode": 18
  },
  {
    "id": 4,
    "name": "Bihar",
    "stateCode": 10
  },
  {
    "id": 5,
    "name": "Chhattisgarh",
    "stateCode": 22
  },
  {
    "id": 6,
    "name": "Delhi / New Delhi",
    "stateCode": 7
  },
  {
    "id": 7,
    "name": "Goa",
    "stateCode": 30
  },
  {
    "id": 8,
    "name": "Gujarat",
    "stateCode": 24
  },
  {
    "id": 9,
    "name": "Haryana",
    "stateCode": 6
  },
  {
    "id": 10,
    "name": "Himachal Pradesh",
    "stateCode": 2
  },
  {
    "id": 11,
    "name": "Jammu & Kashmir",
    "stateCode": 1
  },
  {
    "id": 12,
    "name": "Jharkhand",
    "stateCode": 20
  },
  {
    "id": 13,
    "name": "Karnataka",
    "stateCode": 29
  },
  {
    "id": 14,
    "name": "Kerala",
    "stateCode": 32
  },
  {
    "id": 15,
    "name": "Madhya Pradesh",
    "stateCode": 23
  },
  {
    "id": 16,
    "name": "Maharashtra",
    "stateCode": 27
  },
  {
    "id": 17,
    "name": "Manipur",
    "stateCode": 14
  },
  {
    "id": 18,
    "name": "Meghalaya",
    "stateCode": 17
  },
  {
    "id": 19,
    "name": "Mizoram",
    "stateCode": 15
  },
  {
    "id": 20,
    "name": "Nagaland",
    "stateCode": 13
  },
  {
    "id": 21,
    "name": "Odisha (Orissa)",
    "stateCode": 21
  },
  {
    "id": 22,
    "name": "Punjab",
    "stateCode": 3
  },
  {
    "id": 23,
    "name": "Rajasthan",
    "stateCode": 8
  },
  {
    "id": 24,
    "name": "Sikkim",
    "stateCode": 11
  },
  {
    "id": 25,
    "name": "Tamil Nadu",
    "stateCode": 33
  },
  {
    "id": 26,
    "name": "Telangana",
    "stateCode": 36
  },
  {
    "id": 27,
    "name": "Tripura",
    "stateCode": 16
  },
  {
    "id": 28,
    "name": "Uttar Pradesh",
    "stateCode": 9
  },
  {
    "id": 29,
    "name": "Uttarakhand",
    "stateCode": 5
  },
  {
    "id": 30,
    "name": "West Bengal",
    "stateCode": 19
  }
];
export class EnumEx {
  static getNames(e: any) {
    return Object.keys(e).filter(v => isNaN(parseInt(v, 10)));
  }

  static getValues(e: any) {
    return Object.keys(e).map(v => parseInt(v, 10)).filter(v => !isNaN(v));
  }

  static getNamesAndValues(e: any): Array<ValuePair> {
    return EnumEx.getValues(e).map(v => { return { id: v, name: e[v] as string }; });
  }
}

export enum DBOperation {

  unknown = 0, contains = 1, doesNotContain = 2, startsWith = 3, endsWith = 4,
  equalTo = 5, notEqualTo = 6, greaterThan = 7, greaterThanOrEqualTo = 8,
  lessThan = 9, lessThanOrEqualTo = 10, in = 11, notIn = 12, isNULL = 13,
  isNotNull = 14, dateEqual = 15, dateNotEqual = 16, dateGreaterThan = 17,
  dateGreaterThanOrEqual = 18, dateLessThan = 19, dateLessThanOrEqual = 20, orderBy = 21,
  doesNotStartsWith = 22, doesNotEndsWith = 23
}
export enum SortOperation {
  none = 0, ascending = 1, descending = 2
}
export enum LogicalOperation {
  none = 0, and = 1, or = 2
}
export enum PairOperation {
  none = 0, open = 1, close = 2
}
export interface IUserStates {
  customerString: string,
  dealerString: string,
  merchantString: string
}
export class OtherConfigurations {
  commissionFee: FeeConfig = { onReturns: false, onTotalAmount: true };
  serviceFee: FeeConfig = { onReturns: false, onTotalAmount: true };
}
interface FeeConfig {
  onReturns: boolean,
  onTotalAmount: boolean
}

export var paymentMode = {
  0: "Cash Back",
  1: "Cash",
  2: "Cheque",
  3: "Online",
  4: "OperationsCash",
  5: "Instamojo",
  6: "GPay_Manual",
  7: "NEFT_Manual",
  8: "Paysharp",
  9: "OrderPlaced",
  10: "OrderModify",
  11: "OrderCancel"
};

export enum SetGenerationType {
  Buying = 1,
  NonBuying = 2,
  None = 99
}

export interface IncentiveSlab { id: string, max: number, min: number, name: string, amount: number }


export enum WhereFilterOp {
  LessThan = "<",
  GreaterThan = ">",
  EqualTo = "==",
  LessThanOrEqualTo = "<=",
  GreaterThanOrEqualTo = ">=",
  NotEqualTo = "!=",
  ArrayContains = "array-contains",
  ArrayContainsAny = "array-contains-any",
  In = "in",
  NotIn = "not-in"
}

export enum ClusterType {

  General = 1,
  WholeSaler = 2,
  None = 99
}


export enum TaskStatus {
  CHECKED_IN = 1,
  CHECKED_OUT = 2,
  NONE = 99
}


export enum MessageQueueStatus {
  Not_Started = 1,
  Started = 2,
  In_Progress = 3,
  Completed = 4,
  Errors = 5
}

export enum CustomerCount {
  GREATER_THAN = 1,
  LESS_THAN = 2,
  EQUAL_TO = 3,
  ZERO = 4
}

export enum AttendanceStatus {
  CHECKED_IN = 1,
  CHECKED_OUT = 2,
  REASON_FOR_EARLY_LOGOUT = 3,
  REASON_FOR_EARLY_LOGOUT_ACCEPTED = 4,
  REASON_FOR_EARLY_LOGOUT_REJECTED = 5,
  APPLY_LEAVE = 6,
  LEAVE_ACCEPTED = 7,
  LEAVE_REJECTED = 8,
  LEAVE_CANCELLED = 9,
  NONE = 99
}
