import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, lastValueFrom, map, takeUntil } from "rxjs";
import { User } from "firebase/auth";
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  getIdTokenResult,
  getIdToken,
  signInWithCustomToken,
  UserCredential
} from "@angular/fire/auth";
import { LocalStoreService } from ".";
import { Firestore, collection, collectionData, doc, docData, getDoc, query } from "@angular/fire/firestore";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.userSubject.asObservable();
  userDocData$ = new BehaviorSubject(null);
  pages$ = new BehaviorSubject([]);
  permission$ = new BehaviorSubject({});
  roles$ = new BehaviorSubject([]);
  private _unsubscribeAll: Subscription[] = [];

  constructor(
    private auth: Auth,
    private ls: LocalStoreService,
    private firestore: Firestore,
    private http: HttpClient

  ) {

    authState(this.auth).subscribe((user) => {
      console.log("user>>>>>>>>>>>", user);
      if (user) {
        this.ls.setItem("uid", user.uid);
        this.userSubject.next(user);
        this.subscribeUserData();
        this.subscribePageData();
        this.subscribeRolesData();
      } else {
        console.log(window.location.href);
        if (!window.location.href.includes("/sessions/signin")) {
          window.open(`${environment.ssoUrl}/login?redirectto=http://${window.location.host}/sessions/signin`, "_self")
        }
      }
    });
  }

  getCurrentUser(): Observable<User | null> {
    return this.user$;
  }

  async signUp(email: string, password: string): Promise<void> {
    try {
      await createUserWithEmailAndPassword(this.auth, email, password);
    } catch (error) {

    }
  }

  async signIn(email: string, password: string): Promise<any> {
    try {
      await signInWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      console.log(error)
    }
  }

  // async signOut(): Promise<void> {
  //     try {
  //         await signOut(this.auth);
  //     } catch (error) { }
  // }

  async signOut(): Promise<any> {
    try {
      let functionCall$ = this.http.post(`${environment.cloudFunctionsUrl}/revoke_user_token_uid`, { uid: this.userSubject.value.uid })
      await lastValueFrom(functionCall$)
      window.location.reload()
    } catch (error) { }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (error) { }
  }

  async updateProfile(displayName: string, photoURL: string): Promise<void> {
    const user = this.auth.currentUser;

    if (!user) {
      // Handle the case where no user is authenticated
      throw new Error('No user is authenticated.');
    }

    try {
      await updateProfile(user, {
        displayName,
        photoURL,
      });
    } catch (error) {
      // Handle errors here
      console.error('Error updating profile:', error);
      throw error; // You can rethrow the error or handle it as needed
    }
  }


  isLoggedIn(): Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user)
    );
  }


  async getUserIDTokenAndCache(): Promise<string | null> {
    try {

      const user = this.auth.currentUser;

      if (!user) {
        console.error('No user is authenticated.');
        return null; // Return null or handle the absence of a user as needed
      }

      const tokenResult = await getIdTokenResult(user);
      const expirationTime = new Date(tokenResult.expirationTime);
      const tokenExpired = Date.now() >= expirationTime.getTime();

      if (tokenExpired) {
        // Token is expired, refresh it
        const refreshedToken = await getIdToken(user, true);
        this.ls.setItem('idToken', refreshedToken);
        return refreshedToken;
      } else {
        // Token is still valid, use it
        const cachedToken = this.ls.getItem('idToken');
        return cachedToken || tokenResult.token; // Return the cached token or the current token if there's no cached token
      }
    } catch (error) {
      console.error('Error checking or refreshing idToken:', error);
      throw error; // You can rethrow the error or handle it as needed
    }
  }

  setPermission() {
    if (
      this.userDocData$.value &&
      this.pages$.value.length > 0 &&
      this.roles$.value.length > 0
    ) {
      const newPermissions = {};
      const assignedRoles = this.userDocData$.value.roles || [];
      const roleList = this.roles$.value.filter((ele) => assignedRoles.includes(ele.id))

      for (const page of this.pages$.value) {
        newPermissions[page.page_id] = {
          view: false,
          create: false,
          delete: false,
          edit: false,
          import: false,
          export: false,
        };
      }

      for (const role of roleList) {
        for (const page of this.pages$.value) {
          newPermissions[page.page_id] = {
            view: this.updatePermission(
              newPermissions[page.page_id]["view"],
              role.permissions[page.page_id]?.view
            ),
            create: this.updatePermission(
              newPermissions[page.page_id]["create"],
              role.permissions[page.page_id]?.create
            ),
            delete: this.updatePermission(
              newPermissions[page.page_id]["delete"],
              role.permissions[page.page_id]?.delete
            ),
            edit: this.updatePermission(
              newPermissions[page.page_id]["edit"],
              role.permissions[page.page_id]?.edit
            ),
            import: this.updatePermission(
              newPermissions[page.page_id]["import"],
              role.permissions[page.page_id]?.import
            ),
            export: this.updatePermission(
              newPermissions[page.page_id]["export"],
              role.permissions[page.page_id]?.export
            ),
          };
        }
      }
      if (roleList && roleList.length > 0) {
        for (const page of this.pages$.value) {
          newPermissions[page.page_id] = {
            view: this.updatePermission(
              newPermissions[page.page_id]["view"],
              this.userDocData$.value.extended_permissions[page.page_id]?.view
            ),
            create: this.updatePermission(
              newPermissions[page.page_id]["create"],
              this.userDocData$.value.extended_permissions[page.page_id]?.create
            ),
            delete: this.updatePermission(
              newPermissions[page.page_id]["delete"],
              this.userDocData$.value.extended_permissions[page.page_id]?.delete
            ),
            edit: this.updatePermission(
              newPermissions[page.page_id]["edit"],
              this.userDocData$.value.extended_permissions[page.page_id]?.edit
            ),
            import: this.updatePermission(
              newPermissions[page.page_id]["import"],
              this.userDocData$.value.extended_permissions[page.page_id]?.import
            ),
            export: this.updatePermission(
              newPermissions[page.page_id]["export"],
              this.userDocData$.value.extended_permissions[page.page_id]?.export
            ),
          };
        }
      }
      this.permission$.next(newPermissions);
    }
  }

  private updatePermission(existingValue: boolean, newValue: boolean | undefined): boolean {
    return existingValue || (newValue !== undefined && newValue);
  }

  getPageAccessPermission(pageId) {
    return this.permission$.value[pageId];
  }

  signinWithToken(token: any) {
    return signInWithCustomToken(this.auth, token).then((res) => {
      console.log(res);
      return res;
    });
  }

  subscribeUserData() {
    const docRef = doc(this.firestore, `Users/${this.ls.getItem("uid")}`);
    docData(docRef, { idField: "id" }).subscribe((res) => {
      this.userDocData$.next(res);
      this.setPermission();
    });
  }

  subscribePageData() {
    const aCollection = collection(this.firestore, "pages");
    let q = query(aCollection);
    collectionData(q, { idField: "id" }).subscribe((res) => {
      this.pages$.next(res);
      this.setPermission();
    });
  }

  async getUserById(id: any) {
    const aDoccument = doc(this.firestore, `Users/${id}`);
    let item = (await getDoc(aDoccument)).data();
    return item;
  }

  subscribeRolesData() {
    const aCollection = collection(this.firestore, "Roles");
    let q = query(aCollection);
    collectionData(q, { idField: "id" }).subscribe((roles) => {
      this.roles$.next(roles);
      this.setPermission();
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.forEach((subscription) => subscription.unsubscribe());
  }

}
